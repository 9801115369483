<template>
  <div class="AboutPage">
    <div class="AboutPage__description">
      <h1>
        About
      </h1>
      The {{ siteConstants.siteName }} Market is a crowdsourced marketplace, which means businesses, brands, suppliers, sellers, and even customers can contribute and add content to the website, making it a complete and up-to-date source of information and shopping destination.
      <h2>
        How does it work?
      </h2>
      <h3>
        For Customers
      </h3>
      Except for shopping directly, our customers can create items and add their favourite products and brands to the ecosystem.
      <h3>
        For Brands
      </h3>
      If you are a brand, this is a great opportunity promote your products!
      Brands can create items, add and claim products, manage product pages and listings, and also sell items directly.
      <h3>
        For Suppliers
      </h3>
      If you are a stockist and want to boost your sales, start selling with us!
      Suppliers, fulfilment companies, shops and direct sellers can add new products or sell items existing in our ecosystem.
      <br>
      <br>
      <br>
      <h2>
        Add Listing
      </h2>
      Are there any new vegan products you would like to see on the {{ siteConstants.siteName }} market?
      You can do it here: <router-link to="/add-listing">Add listing</router-link>
      <h3>
        Would you like to promote your brand?
      </h3>
      Become a brand partner and promote your products! Create or claim your business profile, manage product pages and make them neat and pretty to show the customers how amazing your products are!
      <br>
      <router-link to="/account">
        Create your brand profile
      </router-link>
      <br>
      <router-link to="/account">
        Claim your brand profile
      </router-link>
      <h3>
        Would you like to sell your products directly?
      </h3>
      If you are a vegan shop, fulfilment company, product manufacturer and direct seller, you stock vegan products, and you look for ways to boost your sales, start selling them on our marketplace.
      Create your business profile and start selling today!
      <br>
      <router-link to="/account">
        Create your seller profile
      </router-link>
      <br>
      <router-link to="/account">
        Claim your seller profile
      </router-link>
      <!-- <vue-markdown>{{$ml.get('aboutPage.description')}}</vue-markdown> -->
    </div>
  </div>
</template>

<script>
// import VueMarkdown from 'vue-markdown'
import siteConstants from '@/const.js'

export default {
  name: 'AboutPage',
  components: {
    // VueMarkdown
  },
  data () {
    return {
      siteConstants
    }
  }
}
</script>

<style scoped lang="stylus">
.disabled
  opacity 0.1

.AboutPage
  display flex
  background white
  align-items flex-start
  overflow-y auto
  justify-content center
  height 100%
  flex-wrap wrap
  padding 50px 0
  box-sizing border-box
  &__icon
    margin-top 70px
    font-size 140px
    display inline-block
  &__description
    padding: 0 10px;
    text-align left
    line-height 1.6
    max-width 650px
    display inline-block
    box-sizing border-box
    font-size 14px
    /deep/ h1
      font-size 27px
      font-weight 300
      color black
    h1
      font-family: 'Bebas Neue', sans-serif;
    h2, h3
      font-family: Helvetica
      margin 30px 0 10px 0

@media screen and (max-width: 868px)
  .AboutPage
    padding 30px 0
</style>
